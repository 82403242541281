import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { jwtDecode } from "jwt-decode";
import UserPool from "../../UserPool";
import Cookies from "js-cookie";
import "./login.css";

export const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [boxPosition, setBoxPosition] = useState("");
    const [coverPosition, setCoverPosition] = useState("");
    const jsCookie = Cookies.get("accessToken");

    if (jsCookie !== undefined) {
        console.log("Authenticated:", true);
        return <Navigate to="/dashboard" />;
    } else {
        console.log("Authenticated:", false);
        Cookies.remove("accessToken");
        sessionStorage.clear();

        const allowedPaths = ["/login", "/contact-us", "/reset-password"];
        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Form submitted");

        if (!["ryan@spotparking.app", "cooper@spotparking.app", "dean@spotparing.app", "jeff@spotparking.app"].includes(username.toLowerCase())) {
            setErrorMessage("Invalid username or password");
            return;
        }


        const tempUsername = username.trim().toLowerCase();
        const tempPassword = password.trim();
        const user = new CognitoUser({
            Username: tempUsername,
            Pool: UserPool
        });

        const authDetail = new AuthenticationDetails({
            Username: tempUsername,
            Password: tempPassword
        });

        user.authenticateUser(authDetail, {
            onSuccess: (data) => {
                console.log("Success:", data);
                setBoxPosition("-110");

                setTimeout(() => {
                    setCoverPosition("-1900vh");
                }, 500);

                setTimeout(() => {
                    Cookies.set("accessToken", data.getAccessToken().getJwtToken(), { expires: 7 });
                    window.location.href = "/dashboard";
                }, 2000);
                const decodedToken = jwtDecode(data.getAccessToken().getJwtToken(), { header: true });
                console.log("Decoded Token:", decodedToken.kid);
            },
            onFailure: (err) => {
                console.error("Failure:", err);
                setErrorMessage(err.message);
            },
            newPasswordRequired: (data) => {
                window.location.href = "/reset-password";
                console.log("New password required:", data);
            }
        });
    }

    const login = () => {
        return (
            <div className="h-fit w-fit min-w-96 rounded-2xl p-4 bg-white shadow-md fcc z-50">
                <h1 style={{ color: "#323232", fontSize: "50px", fontWeight: "bold", marginBottom: "10px", marginTop: "-15px" }}>Login</h1>
                <div style={{ width: "75%" }}>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <div className="login-title">
                                Email
                            </div>
                            <input
                                type="text"
                                value={username}
                                className="login-input"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div className="login-title">
                                Password
                            </div>
                            <input
                                type="password"
                                value={password}
                                className="login-input"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p className="login-error-message">{errorMessage}</p> : null}
                        <br />
                        <br />
                        <button className="login-button" type="submit">Login</button>
                    </form>
                </div>
            </div>
        )
    }

    const square = (width, height, x, y, rotation) => {
        const newY = boxPosition ? boxPosition : y;

        return (
            <div className="background-square" style={{
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                transform: `rotate(${rotation}deg)`,
                top: `${newY}vh`,
                left: `${x}vw`,
            }}></div>
        );
    }

    const coverer = () => {
        const newY = coverPosition ? coverPosition : "100vh";

        return (
            <div className="coverer" style={{ top: newY }}></div>
        )
    }

    return (
        <div className=" overflow-hidden relative fcc h-screen" style={{ background: "linear-gradient(to top, #FFE374, #474747)" }}>
            {coverer()}
            {login()}
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
            }}>
                {square(70, 50, 20, -25, 40)}
                {square(70, 70, 0, 80, -20)}
                {square(70, 70, 90, 40, -20)}
            </div>
        </div>
    );
}