import React, { useState } from "react";
import "../../../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Toaster, toast } from "sonner"
import "./settings.css";

export const Users = () => {
    const [tableData, setTableData] = useState(JSON.parse(sessionStorage.getItem("employeeData")));
    const [saveMessage, setSaveMessage] = useState("Save");
    const [checkedIndexes, setCheckedIndexes] = useState([]);
    const employeeData = JSON.parse(sessionStorage.getItem("employeeData"));
    const API_URL = "https://ntbd5thmk0.execute-api.us-east-1.amazonaws.com/default/EmployeeUserAPI";
    const API_KEY = process.env.REACT_APP_API_KEY;

    const tableRowStyle = {
        padding: "10px",
    };

    const tableCellStyle = {
        borderRadius: "8.5px",
        height: "35px",
        width: "100%",
        marginLeft: "10px",
        marginRight: "10px",
        marginTop: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center", // Add this line to align text horizontally
        fontSize: "20px",
        textAlign: "center",
        border: "transparent",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    }

    const handleEdit = (index, field, value) => {
        // Update the tableData array with the new value
        const updatedTableData = [...tableData];
        updatedTableData[index][field] = value;
        setTableData(updatedTableData);
        console.log(tableData)
    };

    const handleCheck = (index) => {
        console.log(index)
        const updatedIndexes = [...checkedIndexes];
        if (updatedIndexes.includes(index)) {
            updatedIndexes.splice(updatedIndexes.indexOf(index), 1);
        } else {
            updatedIndexes.push(index);
        }
        setCheckedIndexes(updatedIndexes);
    };

    const saveButton = () => {
        const saveData = async () => {
            const pushExtraRows = (tableData, employeeData) => {
                const sortedTableData = tableData.sort((a, b) => a.userID.localeCompare(b.userID));
                const sortedEmployeeData = employeeData.sort((a, b) => a.userID.localeCompare(b.userID));
                const extraRows = sortedTableData.slice(sortedEmployeeData.length);
                sortedTableData.splice(sortedEmployeeData.length);
                return extraRows
            }

            const findDifferences = (tableData, employeeData) => {
                const sortedTableData = tableData.sort((a, b) => a.userID.localeCompare(b.userID));
                const sortedEmployeeData = employeeData.sort((a, b) => a.userID.localeCompare(b.userID));

                const differences = [];
                for (let i = 0; i < sortedTableData.length; i++) {
                    const tableRow = sortedTableData[i];
                    const employeeRow = sortedEmployeeData[i];
                    let isRowChanged = false;
                    const difference = {};
                    for (const key in tableRow) {
                        if (tableRow[key] !== employeeRow[key]) {
                            difference[key] = tableRow[key];
                            isRowChanged = true;
                        }
                    }
                    if (isRowChanged) {
                        differences.push(tableRow);
                    }
                }
                return differences;
            };

            const newRows = pushExtraRows(tableData, employeeData);
            const differences = findDifferences(tableData, employeeData);
            if (newRows.length !== 0) {
                for (let i in newRows) {
                    differences.push(newRows[i]);
                    setTableData(prevData => [...prevData, newRows[i]]);
                }
            }
            console.log("Differences:", differences);

            for (let i in differences) {
                try {
                    console.log("Data to save:", differences[i]);

                    const response = await fetch(API_URL, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            'x-api-key': API_KEY,
                        },
                        body: JSON.stringify(differences[i]),
                    });
                    if (response.ok) {
                        console.log("Data saved successfully!");
                    } else {
                        console.log("Failed to save data.");
                    }
                } catch (error) {
                    console.error("Error saving data:", error);
                }
            }

            setSaveMessage(<FontAwesomeIcon icon={faCheck} />);
            setTimeout(() => {
                toast.success("User data saved successfully!")
            }, 3000);
        };


        return (
            <div>
                <button
                    style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "30px",
                        marginTop: "20px",
                        width: "150px",
                        height: "35px",
                        borderRadius: "12px",
                        backgroundColor: "#FFE374",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#323232",
                        cursor: "pointer",
                        border: "transparent",
                        boxShadow: "0px 0px 18px 4px rgba(0, 0, 0, 0.12)",
                        transition: "box-shadow 0.3s",
                    }}
                    onClick={saveData}
                >
                    {saveMessage}
                </button>
            </div>
        );
    };

    const addButton = () => {
        const addNewUser = () => {
            const highestUserId = Math.max(...tableData.map((row) => row.userID));
            const newUser = {
                userID: (highestUserId + 1).toString(),
                lastName: "",
                firstName: "",
                password: "",
                permissions: "Employee",
                photo: "",
            };
            const updatedTableData = [...tableData];
            updatedTableData.push(newUser);
            setTableData(updatedTableData);
        };

        return (
            <div>
                <button
                    style={{
                        display: "block",
                        marginLeft: "30px",
                        marginRight: "auto",
                        marginTop: "20px",
                        width: "150px",
                        height: "35px",
                        borderRadius: "12px",
                        backgroundColor: "white",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#323232",
                        cursor: "pointer",
                        border: "transparent",
                        boxShadow: "0px 0px 18px 4px rgba(0, 0, 0, 0.12)",
                    }}
                    onClick={addNewUser}
                >
                    Add User
                </button>
            </div>
        );
    }

    const removeButton = () => {
        const removeUser = () => {
            const updatedTableData = tableData.filter((_, index) => !checkedIndexes.includes(index));
            setTableData(updatedTableData);

            checkedIndexes.forEach((index) => {
                const userId = tableData[index].userID;
                fetch(`${API_URL}`, {
                    method: 'DELETE',
                    body: JSON.stringify({ userID: userId }),
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-key': API_KEY
                    },
                })
                    .then(response => {
                        if (response.ok) {
                            console.log(`User with ID ${userId} deleted successfully.`);
                            console.log(response.json())
                        } else {
                            console.error(`Failed to delete user with ID ${userId}.`);
                        }
                    })
                    .catch(error => {
                        console.error(`An error occurred while deleting user with ID ${userId}.`, error);
                    });
            });

            setCheckedIndexes([]);
        };

        return (
            <div style={{
                display: "block",
                marginLeft: "30px",
                marginRight: "auto",
            }}>
                <button
                    style={{
                        display: "block",
                        marginLeft: "30px",
                        marginRight: "auto",
                        marginTop: "20px",
                        width: "150px",
                        height: "35px",
                        borderRadius: "12px",
                        backgroundColor: "white",
                        fontSize: "20px",
                        fontWeight: "bold",
                        color: "#323232",
                        cursor: "pointer",
                        border: "transparent",
                        boxShadow: "0px 0px 18px 4px rgba(0, 0, 0, 0.12)",
                    }}
                    onClick={removeUser}
                >
                    Remove User
                </button>
            </div>
        );
    }

    return (
        <div>
            <Toaster richColors />
            <div className="settings-container">
                <table style={{ width: "100%", borderCollapse: "collapse", borderRadius: "12px" }}>
                    <thead>
                        <tr style={{ display: "table-row", background: "#323232", color: "#FFE374", borderRadius: "12px" }}>
                            <th style={{ padding: "10px", borderBottomLeftRadius: "12px", borderTopLeftRadius: "12px" }}>Select</th>
                            <th style={{ padding: "10px", width: "75px" }}>User ID</th>
                            <th style={{ padding: "10px" }}>First Name</th>
                            <th style={{ padding: "10px" }}>Last Name</th>
                            <th style={{ padding: "10px" }}>Permissions</th>
                            <th style={{ padding: "10px", borderBottomRightRadius: "12px", borderTopRightRadius: "12px", width: "10%" }}>Photo</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.sort((a, b) => a.userID.localeCompare(b.userID)).map((row, index) => (
                            <tr key={index}>
                                <td style={tableRowStyle}>
                                    <div style={tableCellStyle}>
                                        <input
                                            type="checkbox"
                                            className="select-check"
                                            onChange={(e) => handleCheck(index)}
                                        />
                                    </div>
                                </td>
                                <td style={tableRowStyle}>
                                    <div style={tableCellStyle}>
                                        {row.userID}
                                    </div>
                                </td>
                                <td style={tableRowStyle}>
                                    <input
                                        type="text"
                                        value={row.firstName}
                                        style={tableCellStyle}
                                        onChange={(e) => handleEdit(index, "firstName", e.target.value)}
                                    />
                                </td>
                                <td style={tableRowStyle}>
                                    <input
                                        type="text"
                                        value={row.lastName}
                                        style={tableCellStyle}
                                        onChange={(e) => handleEdit(index, "lastName", e.target.value)}
                                    />
                                </td>
                                <td style={tableRowStyle}>
                                    <select
                                        value={row.permissions || "Admin"}
                                        className="dropdown"
                                        onChange={(e) => handleEdit(index, "permissions", e.target.value)}
                                    >
                                        <option value="Admin">Admin</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Employee">Employee</option>
                                    </select>
                                </td>
                                <td style={tableRowStyle}>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => handleEdit(index, "photo", e.target.files[0])}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {addButton()}
                    {removeButton()}
                    {saveButton()}
                </div>
            </div>
        </div>
    );
};
