import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import "./directory.css";
//import Cacher from "../../Cacher";

export const Directory = () => {
    const uniData = JSON.parse(sessionStorage.getItem("allOrgData"));
    console.log(uniData)
    const [searchTerm, setSearchTerm] = useState("");
    // eslint-disable-next-line
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    // const [showEditor, setShowEditor] = useState(false);
    sessionStorage.setItem("editData", "");

    // Redirect to dashboard if no data is found
    if (uniData.length === 0) {
        window.location.href = "/dashboard";
    }

    const onResultClick = (uni) => {
        sessionStorage.setItem("editData", JSON.stringify(uni));
    }

    // Update search term
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter data based on search term
    const filteredUniData = uniData.filter((item) =>
        item.orgName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // if data is loaded then set opacity to 1
    useEffect(() => {
        setTimeout(() => {
            setIsDataLoaded(true);
        }, 0);
    })

    const NewAccountButton = () => {
        return (
            <Link to="/directory/new-account" className="absolute fcc text-center w-20 h-20 bg-gradient-to-l from-gray-100 to-gray-400 bottom-8 hover:bg-gradient hover:from-gray-200 hover:to-gray-500 right-8 text-4xl text-spotGrey rounded-3xl">
                <FaPlus />
            </Link>
        );
    }

    return (
        <div>
            <div className="settings-container">
                <h1 className="directory-title">Account Search</h1>
                <input
                    type="text"
                    placeholder="Search Organization"
                    className="search-bar"
                    value={searchTerm}
                    key={"search-bar"}
                    onChange={handleSearch}
                />
                {searchTerm === "" ? null :
                    <div key={"result-container"} className="result-container">
                        {filteredUniData.map((uni, index) => {
                            return (
                                <Link
                                    to="/directory/university"
                                    className="result-item-container"
                                    onClick={() => onResultClick(uni)}
                                    key={index}
                                >
                                    <div className="result-item">
                                        {uni["orgName"]}
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                }
            </div>
            <NewAccountButton />
        </div>
    );
}