import React from 'react'
import "./statistics.css"

export default function Statistics() {
    const allSchoolData = JSON.parse(sessionStorage.getItem("orgData"));
    console.log(allSchoolData);

    return (
        <div>
            <div className='stats-container'>
                Future Stats Page
            </div>
        </div>
    )
}
