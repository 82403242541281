import { CognitoUserPool } from "amazon-cognito-identity-js";

// This info is gotten from the AWS Cognito console
const poolData = {
    UserPoolId: "us-east-1_ltGLCzKaG",
    ClientId: "2e69m443tbbqhnlgdfstnv2ksl"
}

const UserPool = new CognitoUserPool(poolData);

export default UserPool;