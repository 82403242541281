import React, { useState } from 'react';
import "../../../styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Toaster, toast } from 'sonner';
import "./settings.css";

export const SingleSetting = (title, defaultInput) => {
    const [settingValue, setSettingValue] = useState(defaultInput);
    const [alertValue, setAlertValue] = useState("");
    const [seed, setSeed] = useState(0);
    const API_URL = "https://edyw83zmk2.execute-api.us-east-1.amazonaws.com/default/UniversityTableAPI";
    const API_KEY = process.env.REACT_APP_API_KEY;
    const [saveText, setSaveText] = useState("Save");

    const titleDictionary = {
        "UniversityName": "University Name",
        "baseFee": "Base Fee",
        "Lots": "Lots",
        "defaultMapZoom": "Default Map Zoom",
        "primaryColor": "Primary Color",
        "secondaryColor": "Secondary Color",
        "tertiaryColor": "Tertiary Color",
        "uniCoordinates": "University Coordinates",
        "violationTimeGoal": "Violation Time Goal",
        "violationTypes": "Violation Types"
    }

    const formattedTitle = titleDictionary[title];

    const violationTypes = [
        "After Hours Parking",
        "Blocking Traffic",
        "Carpool Violation",
        "Emergency Lane",
        "Faculty Parking",
        "Fire Lane",
        "Hash Mark Violation",
        "Handicap Parking",
        "Line Straddling",
        "Loading Zone",
        "Metered Parking",
        "No Carpool Limit",
        "No Permit",
        "Overtime Parking",
        "Plate Not Visible",
        "Prohibited Parking",
        "Reserved Parking",
        "Service Vehicle",
        "Sidewalk Violation",
        "Visitor Parking",
        "Wrong Direction"
    ];

    // Send data to the UniversityTable and mark it as saved
    const handleSave = () => {
        sessionStorage.setItem(title, settingValue);
        const allSchoolData = JSON.parse(sessionStorage.getItem("schoolData"));

        //Update the setting that was changed
        const newData = allSchoolData
        newData[title] = settingValue;

        fetch(API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "x-api-key": API_KEY
            },
            body: JSON.stringify(newData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Response:', data)
            })
            .catch(error => {
                console.error('Error:', error)
                toast.error(title + " was not saved. Please try again.");
            });

        setSaveText(<FontAwesomeIcon icon={faCheck} />);
        setAlertValue("");
        setTimeout(() => {
            toast.success(formattedTitle + " has been saved!");
            setSaveText("Save");
        }, 3000);
    };

    var currentChecks = []
    if (title === "violationTypes") {
        try { 
            currentChecks = settingValue.split(", ");
        } catch (error) {
            currentChecks = settingValue;
        }
    }

    function addCheckedItem(item) {
        currentChecks.push(item);
        setSettingValue(currentChecks);
    }

    function removeCheckedItem(item) {
        const updatedChecks = currentChecks.filter(check => check !== item);
        setSettingValue(updatedChecks);
    }

    const renderer = (title) => {
        if (title === "violationTypes") {
            return (
                <div style={{ marginBottom:"15px" }}>
                    <div key={"inputs"} style={{ display: "flex", flexWrap: "wrap" }}>
                        {violationTypes.map((type, index) => (
                            <div key={index} style={{ display: "flex", alignItems: "center", fontSize:"12px" }}>
                                <input
                                    type="checkbox"
                                    checked={currentChecks.includes(type)}
                                    style={{ cursor:"pointer", accentColor:"#FFE374" }}
                                    onChange={() => {
                                        setSeed(seed + 1);
                                        if (currentChecks.includes(type)) {
                                            removeCheckedItem(type);
                                        } else {
                                            addCheckedItem(type);
                                        }
                                    }}
                                />
                                <label style={{ marginLeft: "5px", marginRight: "15px", marginTop:"15px"}}>{type}</label>
                            </div>
                        ))}
                        <p className='alert-values'>{alertValue}</p>
                    </div>
                </div>
            );
        } else {
                return (
                <div style={{ marginBottom:"15px" }}>
                    <div key={"inputs"} style={{ display: "flex" }}>
                        <input
                            value={settingValue} 
                            className='single-setting-input'
                            onChange={(e) => {
                                setSettingValue(e.target.value);
                                setSaveText("Save");
                            }} 
                        />
                        <p className='alert-values'>{alertValue}</p>
                    </div>
                </div>
            )
        }
    }

    return (
        <div key={title} className='single-setting-container'>
            <Toaster position='bottom-right' richColors />
            <div key={"headers"}> 
                <h2 style={{ marginBottom: "20px", display:"flex", alignContent:"top", marginRight:"10px" }}>{formattedTitle}</h2> 
            </div>
            {renderer(title)}
            <button key={title} className='single-setting-save-button' onClick={handleSave}>
                {saveText}
            </button>
        </div>
    );
}