import React from 'react'
import "./apiConsole.css"

export default function ApiConsole() {
    const allSchoolData = JSON.parse(sessionStorage.getItem("orgData"));
    console.log(allSchoolData);

    return (
        <div>
            <div className='stats-container'>
                Future Api Console
            </div>
        </div>
    )
}
