import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./components/pages/dashboard/Dashboard";
import { MyAccount } from "./components/pages/settings/MyAccount";
import { Users } from "./components/pages/settings/Users";
import { Signup } from "./components/pages/SignUp";
import { Login } from "./components/pages/Login";
import ResetPassword from "./components/pages/ResetPassword";
import { UniPage } from "./components/pages/universities/UniPage";
import Statistics from "./components/pages/statistics/Statistics";
import Sidebar from "./components/sidebar/Sidebar";
import "./styles.css";

import Cookies from "js-cookie";
import { Directory } from "./components/pages/universities/Directory";
import ApiConsole from "./components/pages/apiConsole/ApiConsole";
import WebsiteHome from "./components/pages/website/WebsiteHome";
import MapView from "./components/pages/mapView/MapView";

export default function App() {
    const jsCookie = Cookies.get("accessToken");
    const allowedPaths = ["/login", "/contact-us", "/reset-temp-password", "/reset-password"];

    if (jsCookie !== undefined) {
        console.log("Authenticated:", true);
    } else {
        console.log("Authenticated:", false);
        sessionStorage.clear();
        Cookies.remove("accessToken");

        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
        }
    }

    return (
        <Router>
            {!allowedPaths.includes(window.location.pathname) && <Sidebar />}
            <Routes>
                {/* Main Pages */}
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/directory" element={<Directory />} />
                <Route path="/directory/university" element={<UniPage />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/api-console" element={<ApiConsole />} />
                <Route path="/map" element={<MapView />} />
                {/* Website */}
                <Route path="/website/home" element={<WebsiteHome />} />
                {/* Settings */}
                <Route path="/settings/myaccount" element={<MyAccount />} />
                <Route path="/settings/users" element={<Users />} />
                {/* Login Stuff */}
                <Route path="/signup" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
        </Router>
    );
}