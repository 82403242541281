import React, { useState } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from '../../UserPool';
import "./login.css"

const ResetPassword = () => {
    const [username, setUsername] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [boxPosition, setBoxPosition] = useState("");
    const [coverPosition, setCoverPosition] = useState("");

    const handleResetPassword = async (event) => {
        event.preventDefault();

        const user = new CognitoUser({
            Username: username,
            Pool: UserPool,
        });

        user.authenticateUser(new AuthenticationDetails({
            Username: username,
            Password: oldPassword,
        }), {
            onSuccess: (data) => {
                user.changePassword(oldPassword, newPassword, (error, result) => {
                    if (error) {
                        console.log(error);
                        setErrorMessage(error.message);
                    } else {
                        // Password changed successfully
                        setErrorMessage('');
                        setOldPassword('');
                        setNewPassword('');
                        console.log(result);
                        setBoxPosition("-110"); 
                
                        setTimeout(() => {
                            setCoverPosition("-10vh");
                        }, 1000);

                        setTimeout(() => {
                            sessionStorage.setItem("isLoggedIn", true);
                            document.cookie = `accessToken=${data.getAccessToken().getJwtToken()};`;
                            window.location.href = '/dashboard';
                        }, 2000);
                    }
                });
            },
            onFailure: (error) => {
                console.log(error);
                setErrorMessage(error.message);
            },
            newPasswordRequired: () => {
                user.completeNewPasswordChallenge(newPassword, {}, {
                    onSuccess: (data) => {
                        // Password changed successfully
                        setErrorMessage('');
                        setOldPassword('');
                        setNewPassword('');
                        console.log('Password changed successfully');
                        setBoxPosition("-110"); 
                
                        setTimeout(() => {
                            setCoverPosition("-10vh");
                        }, 1000);

                        setTimeout(() => {
                            sessionStorage.setItem("isLoggedIn", true);
                            const expirationTime = new Date(Date.now() + data.getAccessToken().getExpiration() * 1000);
                            document.cookie = `accessToken=${data.getAccessToken().getJwtToken()}; expires=${expirationTime.toUTCString()}; path=/;`;
                            window.location.href = '/dashboard';
                        }, 2000);
                    },
                    onFailure: (error) => {
                        console.log(error);
                        setErrorMessage(error.message);
                    }
                });
            },
        });
    };

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
        borderRadius: "45px",
        boxShadow: "0 1px 28px 10px rgba(0, 0, 0, 0.1)",
        width: "90%",
        maxWidth: "500px",
        height: "500px",
        margin: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "999",
    };

    const titleStyle = {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "-8px",
        color: "#323232"
    };

    const inputStyle = {
        width: "100%",
        maxWidth: "500px",
        height: "40px",
        borderRadius: "8px",
        border: "1px solid #ccc",
        marginBottom: "10px",
        paddingLeft: "10px",
        fontSize: "20px",
        color: "#323232",
    };

    const tile = () => {
        return (
            <div style={containerStyle}>
                <h1 style={{ color: "#323232", fontSize: "50px", fontWeight: "bold", marginBottom: "0px", marginTop:"-10px" }}>Reset Password</h1>
                <div style={{ width:"75%" }}>
                    <form onSubmit={handleResetPassword}>
                        <label>
                            <br />
                            <div style={titleStyle}>
                                Username
                            </div>
                            <input
                                type="text"
                                value={username}
                                style={inputStyle}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div style={titleStyle}>
                                Old Password
                            </div>
                            <input
                                type="password"
                                value={oldPassword}
                                style={inputStyle}
                                onChange={(e) => setOldPassword(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div style={titleStyle}>
                                New Password
                            </div>
                            <input
                                type="password"
                                value={newPassword}
                                style={inputStyle}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p style={{ color: "red", fontSize: "16px", marginTop: "5px", marginBottom:"-15px" }}>{errorMessage}</p> : null}
                        <br />
                        <br />
                        <button style={{
                            display: "block",
                            justifyContent: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: "175px",
                            height: "50px",
                            borderRadius: "20px",
                            backgroundColor: "#FFE374",
                            fontSize: "32px",
                            fontWeight: "bold",
                            color: "white",
                            cursor: "pointer",
                            border: "transparent",
                            boxShadow: "0px 0px 18px 4px rgba(0, 0, 0, 0.12)",
                            textShadow: "0px 0px 18px rgba(0, 0, 0, 0.12)",
                        }} type="submit">Reset</button>
                    </form>
                    <div style={{ 
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        fontSize: "12px",
                        color: "#323232",
                        marginBottom:"-20px",
                    }}>
                        <a style={{ textDecoration:"underline", marginLeft:"5px" }}href="/login">Go Back</a>
                    </div>
                </div>
            </div>
        )
    }

    const square = (width, height, x, y, rotation) => {
        const newY = boxPosition ? boxPosition : y;

        return (
            <div style={{
            position: "absolute",
            width: `${width*.75}%`,
            height: `${height*1.25}%`,
            backgroundColor: "#D9D9D9",
            transform: `rotate(${rotation}deg)`,
            borderRadius: "45px",
            opacity: "0.2",
            boxShadow: "0 0px 36px 18px rgba(0, 0, 0, 1.45)",
            top: `${newY}vh`,
            left: `${x}vw`,
            zIndex: "0",
            transition: "all 1s ease-in-out",
            }}></div>
        );
    }

    const coverer = () => {
        const newY = coverPosition ? coverPosition : "100vh";

        return (
            <div style={{
                position: "absolute",
                width: "100%",
                height: "200%",
                backgroundColor: "#FFFFFF",
                borderRadius: "45px",
                top: newY,
                left: "0",
                zIndex: "10000",
                transition: "all 1s ease-in-out",
                }}></div>
        )
    }

    return (
        <div style={{ 
            background: "white",//"linear-gradient(to top, #FFE374, #474747)",
            height: "100vh",
        }}>
            {coverer()}
            {tile()}
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: "1",
                overflow: "hidden",
            }}>
            {square(70, 50, 20, -25, 40)}
            {square(70, 70, 0, 80, -20)}
            {square(70, 70, 90, 40, -20)}
            </div>
        </div>
    );
}

export default ResetPassword;