import React from "react";
import "../../../styles.css";
import { SingleSetting } from "./singleSetting";

export const MyAccount = () => {
    const currentUniData = JSON.parse(sessionStorage.getItem("schoolData"));
    const removeList = ["lots"]
    const filteredUniData = Object.fromEntries(
        Object.entries(currentUniData).filter(([key]) => !removeList.includes(key))
    );

    const order = [
        "UniversityName",
        "violationTypes",
        "violationTimeGoal",
        "baseFee",
        "defaultMapZoom",
        "uniCoordinates",
    ];

    const orderedUniData = order.map(key => [key, filteredUniData[key]]);
    const orderedFilteredUniData = Object.fromEntries(orderedUniData);

    return (
        <div>
            <div className="settings-container">
                {Object.entries(orderedFilteredUniData).map(([key, value]) => (
                    SingleSetting(key, value, value)
                ))}
            </div>
        </div>
    );
};
