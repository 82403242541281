import React from "react";

const WebsiteHome = () => {

    return (
        <div>
            <div className="fcenter">
                <h1>Home Page</h1>
            </div>
        </div>
    );
}

export default WebsiteHome;